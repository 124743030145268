import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
import { get as _get } from 'lodash';

// tslint:disable: no-bitwise
export enum PermissionsValues {
    ADMIN = 1 << 0,
    CREATE_BLOG_POST = 1 << 1,
    DELETE_BLOG_POST = 1 << 2,
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    user = new BehaviorSubject(null);
    permissions: number = 0;

    constructor(public afAuth: AngularFireAuth, private router: Router) {
        this.afAuth.user.subscribe((user: firebase.User) => {
            console.log('afAuth.user: ', user);
            this.user.next(user);
        });

        if (this.afAuth.idTokenResult != null) {
            this.afAuth.idTokenResult.subscribe(idTokenResult => {
                console.log('TCL: AuthService -> constructor -> idTokenResult', idTokenResult);
                this.permissions = _get(idTokenResult, 'claims.permissions');
                console.log('TCL: AuthService -> constructor -> this.permissions', this.permissions);
            });
        }
    }

    loginWithGoogle(): void {
        console.log('Logging in');
        this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(() => this.router.navigate(['admin']));
    }

    logout(): void {
        console.log('Loggin out');

        this.afAuth.auth.signOut();
    }

    public getValuesOfPermissionsValue(role: PermissionsValues): number {
        return role;
    }

    public hasPermissionForRole(role: PermissionsValues) {
        // tslint:disable-next-line:no-bitwise
        const permissionsValue = this.getValuesOfPermissionsValue(role);
        return (permissionsValue & this.permissions) > 0;
    }
}
