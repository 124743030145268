import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-vertiseit-modal',
    templateUrl: './vertiseit-modal.component.html',
    styleUrls: ['./vertiseit-modal.component.scss'],
})
export class VertiseitModalComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef) {}

    ngOnInit() {}
}
