import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
    messages;

    constructor(public authService: AuthService, public afs: AngularFirestore) {
        this.messages = this.afs.collection('/messages').valueChanges();
    }

    ngOnInit() {}
}
