import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
    name: string = '';
    email: string = '';
    message: string = '';
    modalRef: BsModalRef;
    constructor(private afs: AngularFirestore, private modalService: BsModalService) {}

    ngOnInit() {}

    send(template) {
        const message = { name: this.name, email: this.email, message: this.message, date: new Date() };
        this.afs.collection('/messages/').add(message);

        this.name = '';
        this.email = '';
        this.message = '';

        this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered',
        });
    }

    closeModal(): void {
        this.modalRef.hide();
    }
}
