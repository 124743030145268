import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { firestore } from 'firebase';

@Component({
    selector: 'app-timestamp-calendar',
    templateUrl: './timestamp-calendar.component.html',
    styleUrls: ['./timestamp-calendar.component.scss'],
})
export class TimestampCalendarComponent implements OnInit {
    @Output() updatedTimestamp: EventEmitter<firestore.Timestamp> = new EventEmitter();

    date: Date;

    constructor() {}

    ngOnInit() {}

    @Input()
    set timestamp(t: firestore.Timestamp) {
        this.date = t.toDate();
    }

    updateDate(event) {
        this.updatedTimestamp.emit(firestore.Timestamp.fromDate(event));
    }
}
