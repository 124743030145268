import { PermissionsValues } from './../auth.service';
import { IBlogPost } from './../models/blog-post';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, map, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { firestore } from 'firebase';
import { AngularFireStorage } from '@angular/fire/storage';
import { BlogImages } from './blog-image';

@Component({
    selector: 'app-add-blog-post',
    templateUrl: './add-blog-post.component.html',
    styleUrls: ['./add-blog-post.component.scss', '../blog/blog.component.scss'],
})
export class AddBlogPostComponent implements OnInit {
    blogPost: IBlogPost;
    id: string = null;
    uploadProgress: any;
    images: BlogImages = new BlogImages();

    constructor(
        public authService: AuthService,
        public afs: AngularFirestore,
        public storage: AngularFireStorage,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.blogPost = this.defaultBlogPost();

        this.route.paramMap
            .pipe(
                switchMap(params => {
                    this.id = params.get('id');
                    if (this.id !== undefined && this.id !== '') {
                        this.id = this.id;
                        const path = `blogPosts/${this.id}`;
                        return this.afs.doc<IBlogPost>(path).valueChanges();
                    } else {
                        this.id = null;
                        const defaultBlogPost = this.defaultBlogPost();
                        return of(defaultBlogPost);
                    }
                })
            )
            .subscribe((blogPost: IBlogPost) => {
                this.blogPost = blogPost;
                if (this.blogPost != null) {
                    this.blogPost.id = this.id;
                }
            });
    }

    private defaultBlogPost(): IBlogPost {
        return {
            id: null,
            author: 'Andreas Lidholm',
            title: 'My title',
            subtitle: 'The subtitle',
            date: firestore.Timestamp.now(),
            private: true,
            tags: [],
            content:
                '```css\n.some {\n    display: relative\n}\n```\n\n```css\np' +
                ' {\n    color: red !important;\n    line-height: normal!important;\n}' +
                '\np{position:absolute!important}\n```',
            owner: this.getLoggedInUserUid(),
        };
    }

    public allowedToSee() {
        console.log('TCL: AddBlogPostComponent -> allowedToSee -> this.authService', this.authService);
        return this.authService.user != null && this.authService.hasPermissionForRole(PermissionsValues.CREATE_BLOG_POST);
    }

    delete() {
        console.log('About to delete: ', this.id);
        const path = `blogPosts/${this.id}`;
        this.afs.doc<IBlogPost>(path).delete();
        this.router.navigate(['blog']);
    }

    saveBlogPost(): void {
        console.log('Save to firestore here');

        this.blogPost.owner = this.getLoggedInUserUid();

        console.log(this.blogPost);
        console.log(this.id);

        if (this.id !== null) {
            const path = `blogPosts/${this.id}`;
            this.afs.doc<IBlogPost>(path).update(this.blogPost);
        } else {
            this.afs.collection<IBlogPost>('/blogPosts').add(this.blogPost);
        }
        this.router.navigate(['blog']);
    }

    getLoggedInUserUid(): string {
        return this.authService.user.value != null ? this.authService.user.value.uid : '';
    }

    splitTags(tags: string) {
        if (tags == null) {
            return null;
        }
        this.blogPost.tags = tags.split(',').map(t => t.trim());
    }

    joinTags() {
        if (this.blogPost.tags === undefined || this.blogPost.tags === null) {
            return '';
        }
        return this.blogPost.tags.join(', ');
    }

    private getStorageName() {
        // create a random id
        const randomId = Math.random()
            .toString(36)
            .substring(2);
        return `blogPosts/post1/${randomId}`;
    }

    upload(event) {
        // create a reference to the storage bucket location
        const ref = this.storage.ref(this.getStorageName());
        console.log('ref: ', ref);
        // the put method creates an AngularFireUploadTask
        // and kicks off the upload
        const task = ref.put(event.target.files[0]);

        task.snapshotChanges()
            .pipe(
                finalize(async () => {
                    this.images.addImage(await ref.getDownloadURL().toPromise());

                    const image = this.images.getImages()[this.images.getImages().length - 1];
                    this.blogPost.content += `\n![alt text][${image.name}]\n\n[${image.name}]: ${image.downloadUrl} "Logo Title Text 2"`;
                })
            )
            .subscribe();
    }
}
