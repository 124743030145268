import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { filter as _filter, toLower as _toLower, replace as _replace, split as _split, uniq as _uniq, orderBy as _orderBy } from 'lodash';

interface WordInLyricsSong {
  id?: string;
  title: string;
  artist: string;
  lyrics: string;
  words: string[];
}

@Component({
  selector: 'app-word-in-lyrics',
  templateUrl: './word-in-lyrics.component.html',
  styleUrls: ['./word-in-lyrics.component.scss']
})
export class WordInLyricsComponent implements OnInit {
  public songs: Observable<WordInLyricsSong[]>;

  public artist: string;
  public title: string;
  public lyrics: string;
  public words: string[] = ['Words', 'in', 'lyrics', 'will', 'show','up','here'];

  constructor(public authService: AuthService, public afs: AngularFirestore) {
    const songsCollection = afs.collection<WordInLyricsSong>('songs');
    this.songs = songsCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as WordInLyricsSong;
        const id = a.payload.doc.id;
        console.log(id);
        return { id, ...data };
      }))
    );
  }

  ngOnInit() {
  }

  splitLyrics() {
    let text = this.lyrics;
    text = _toLower(text);
    text = _replace(text, /[\.\,\!\?\;\:\#\&\+\n\r]/g, ' ');
    const words = _uniq(_split(text, ' '));
    this.words = _orderBy(_filter(words, x => x !== ''));
  }

  addSong() {
    const songsCollection = this.afs.collection<WordInLyricsSong>('songs');
    songsCollection.add({
      artist: this.artist,
      title: this.title,
      lyrics: this.lyrics,
      words: this.words
    });

    this.artist = '';
    this.title = '';
    this.lyrics = '';
    this.words = [];
  }

  selectSong(song: WordInLyricsSong) {
    this.artist = song.artist;
    this.title = song.title;
    this.lyrics = song.lyrics;
    this.words = song.words;
  }

  deleteSong(song: WordInLyricsSong) {
    const songsCollection = this.afs.collection<WordInLyricsSong>('songs');
    songsCollection.doc(song.id).delete();
  }
}
