import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NetModalComponent } from './net-modal/net-modal.component';
import { VertiseitModalComponent } from './vertiseit-modal/vertiseit-modal.component';
import { JeppesenModalComponent } from './jeppesen-modal/jeppesen-modal.component';

@Component({
    selector: 'app-resume-job',
    templateUrl: './resume-job.component.html',
    styleUrls: ['./resume-job.component.scss'],
})
export class ResumeJobComponent implements OnInit {
    modalRef: BsModalRef;

    @Input()
    title;
    @Input()
    role = '';
    @Input()
    startDate = '';
    @Input()
    endDate = '';
    @Input()
    description;
    @Input()
    modalName: string;

    constructor(private modalService: BsModalService) {}

    ngOnInit() {}

    openResume() {
        let modal: any = NetModalComponent;
        if (this.modalName === 'vertiseit') {
            modal = VertiseitModalComponent;
        }
        if (this.modalName === 'jeppesen') {
            modal = JeppesenModalComponent;
        }

        this.modalRef = this.modalService.show(modal, {
            class: 'modal-dialog-centered',
        });
    }

    closeModal(): void {
        this.modalRef.hide();
    }
}
