import { Component, OnInit, Type } from '@angular/core';
import { Board, ItemType } from './Board';

@Component({
    selector: 'app-bombgame',
    templateUrl: './bombgame.component.html',
    styleUrls: ['./bombgame.component.scss'],
})
export class BombGameComponent implements OnInit {
    rows: number = 5;
    columns: number = 5;
    bombs: number = 10;
    board: Board;
    lives: number = 3;
    markedBombs: number = 0;

    public ItemType = ItemType;

    constructor() {
        this.board = new Board();
    }

    ngOnInit() {}

    public getBoard() {
        return this.board;
    }

    public updateBoard() {
        this.board.updateInputs(this.rows, this.columns, this.bombs);
        this.lives = 3;
        this.markedBombs = 0;
    }

    public getTemplateRowsCss(): string {
        return '4fr repeat(' + this.board.getNumberOfRows() + ', 2fr)';
    }

    public getTemplateColumnsCss(): string {
        return '4fr repeat(' + this.board.getNumberOfColumns() + ', 2fr)';
    }

    public itemClick(type: ItemType, row: number, column: number) {
        // ! Don't allow click on items already clicked

        const returnValue = this.board.setBomb(type, row, column);
        if (!returnValue) {
            this.lives--;
        } else {
            if (type === ItemType.BOMB) {
                this.markedBombs++;
            }
        }

        return false;
    }
}
