export enum ItemType {
    BOMB = 'B',
    SAFE = 'S',
}
/*
Nonogram
10 x 10: Number of bombs: 45, 49, 53

*/
export class Board {
    private numberOfColumns: number;
    private numberOfRows: number;
    private numberOfBombs: number;

    private columnHeaders: string[];
    private rowHeaders: string[];

    private items: any[][]; // rows of columns

    constructor() {
        this.updateBoard();
    }

    public updateInputs(rows: number, columns: number, bombs: number) {
        this.numberOfRows = rows;
        this.numberOfColumns = columns;
        this.numberOfBombs = bombs;

        this.updateBoard();
    }

    public updateBoard() {
        this.items = [];
        this.columnHeaders = [];
        for (let i = 0; i < this.numberOfColumns; i++) {
            this.columnHeaders.push('ch-' + i);
        }

        this.rowHeaders = [];
        for (let i = 0; i < this.numberOfRows; i++) {
            this.rowHeaders.push('rh-' + i);
        }

        for (let iRow = 0; iRow < this.numberOfRows; iRow++) {
            const row = [];
            for (let iColumn = 0; iColumn < this.numberOfColumns; iColumn++) {
                row.push({ row: iRow, column: iColumn, hasBomb: ItemType.SAFE });
            }
            this.items.push(row);
        }

        for (let i = 0; i < this.numberOfBombs; i++) {
            let r = -1;
            let c = -1;
            while (r < 0 || this.items[r][c].hasBomb === ItemType.BOMB) {
                r = Math.floor(Math.random() * this.numberOfRows);
                c = Math.floor(Math.random() * this.numberOfColumns);
            }
            this.items[r][c].hasBomb = ItemType.BOMB;
        }
        this.updateRowHeaders();
        this.updateColumnHeaders();
    }

    private updateRowHeaders() {
        for (let iRow = 0; iRow < this.numberOfRows; iRow++) {
            let rowHeaderText = '';
            let bombCount = 0;
            for (let iColumn = 0; iColumn < this.numberOfColumns; iColumn++) {
                if (this.items[iRow][iColumn].hasBomb === ItemType.BOMB) {
                    bombCount++;
                } else {
                    if (bombCount > 0) {
                        rowHeaderText += ' ' + bombCount;
                        bombCount = 0;
                    }
                }
            }
            if (bombCount > 0) {
                rowHeaderText += ' ' + bombCount;
            }
            this.rowHeaders[iRow] = rowHeaderText.trim();
        }
    }

    private updateColumnHeaders() {
        for (let iColumn = 0; iColumn < this.numberOfColumns; iColumn++) {
            let columnHeaderText = '';
            let bombCount = 0;
            for (let iRow = 0; iRow < this.numberOfRows; iRow++) {
                if (this.items[iRow][iColumn].hasBomb === ItemType.BOMB) {
                    bombCount++;
                } else {
                    if (bombCount > 0) {
                        columnHeaderText += '<br />' + bombCount;
                        bombCount = 0;
                    }
                }
            }
            if (bombCount > 0) {
                columnHeaderText += '<br />' + bombCount;
            }
            this.columnHeaders[iColumn] = this.trim(columnHeaderText, '<br />');
        }
    }

    private trim(input: string, prefix: string): string {
        if (input.startsWith(prefix)) {
            input = input.substring(prefix.length, input.length);
        }
        return input;
    }

    public getNumberOfColumns() {
        return this.numberOfColumns;
    }

    public getNumberOfRows() {
        return this.numberOfRows;
    }

    public getColumnHeaders() {
        return this.columnHeaders;
    }

    public getColumnHeader(column: number) {
        return this.columnHeaders[column];
    }

    public getRowHeader(row: number) {
        return this.rowHeaders[row];
    }

    public getRows() {
        return this.items;
    }

    public getItem(row: number, column: number) {
        return this.items[row][column];
    }

    public setBomb(type: ItemType, row: number, column: number) {
        this.items[row][column].revealed = true;
        this.items[row][column].correct = this.items[row][column].hasBomb === type;
        return this.items[row][column].correct;
    }
}
