export class BlogImage {
    public downloadUrl: string;
    public name: string;

    constructor(url: string, count: number) {
        this.downloadUrl = url;
        this.name = 'image-' + count;
    }
}

export class BlogImages {
    public images: BlogImage[] = [];

    addImage(url: string) {
        const image = new BlogImage(url, this.images.length + 1);
        this.images.push(image);
    }

    getImages() {
        return this.images;
    }
}
