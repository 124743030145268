import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-jeppesen-modal',
    templateUrl: './jeppesen-modal.component.html',
    styleUrls: ['./jeppesen-modal.component.scss'],
})
export class JeppesenModalComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef) {}

    ngOnInit() {}
}
