import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carmel',
  templateUrl: './carmel.component.html',
  styleUrls: ['./carmel.component.scss']
})
export class CarmelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
