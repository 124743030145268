import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { IBlogPost } from '../models/blog-post';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
    blogPosts: any;

    constructor(public authService: AuthService, public afs: AngularFirestore, private router: Router) {
        this.blogPosts = this.afs
            .collection<IBlogPost>('/blogPosts', ref => ref.orderBy('date', 'desc'))
            .valueChanges({ idField: 'id' });
    }

    ngOnInit() {}

    public editPost(blog) {
        this.router.navigate(['add-blog-post', blog.id]);
    }
}
