import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MarkdownModule } from 'ngx-markdown';
import { environment } from '../environments/environment';
import { AdminComponent } from './admin/admin.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { FirstPageComponent } from './first-page/first-page.component';
import { BombGameComponent } from './games/bomb-game/bombgame.component';
import { WordInLyricsComponent } from './games/word-in-lyrics/word-in-lyrics.component';
import { LoginComponent } from './login/login.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { JeppesenModalComponent } from './resume-job/jeppesen-modal/jeppesen-modal.component';
import { NetModalComponent } from './resume-job/net-modal/net-modal.component';
import { ResumeJobComponent } from './resume-job/resume-job.component';
import { VertiseitModalComponent } from './resume-job/vertiseit-modal/vertiseit-modal.component';
import { BlogComponent } from './blog/blog.component';
import { AddBlogPostComponent } from './add-blog-post/add-blog-post.component';
import { CalendarModule } from 'primeng/calendar';
import { TimestampCalendarComponent } from './timestamp-calendar/timestamp-calendar.component';
import { ButtonComponent } from './components/button/button.component';
import { CarmelComponent } from './carmel/carmel.component';

@NgModule({
    declarations: [
        AppComponent,
        ProjectCardComponent,
        ResumeJobComponent,
        ContactFormComponent,
        NetModalComponent,
        VertiseitModalComponent,
        JeppesenModalComponent,
        PortfolioComponent,
        AdminComponent,
        LoginComponent,
        FirstPageComponent,
        WordInLyricsComponent,
        BombGameComponent,
        BlogComponent,
        AddBlogPostComponent,
        TimestampCalendarComponent,
        ButtonComponent,
        CarmelComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        CollapseModule.forRoot(),
        ModalModule.forRoot(),
        FormsModule,
        AnimateOnScrollModule.forRoot(),
        MarkdownModule.forRoot(),
        CalendarModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
    entryComponents: [NetModalComponent, VertiseitModalComponent, JeppesenModalComponent],
})
export class AppModule {}
