import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
    @Input() text: string;
    @Input() link: string;
    @Input() fadeIn: boolean = true;
    @Output() action: EventEmitter<void> = new EventEmitter();

    constructor(private router: Router) {}

    ngOnInit() {}

    load() {
        if (this.link != null && this.link !== '') {
            this.router.navigate([this.link]);
        } else {
            this.action.emit();
        }
    }
}
