import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { floor as _floor, map as _map, filter as _filter } from 'lodash';

@Component({
    selector: 'app-portfolio',
    templateUrl: './portfolio.component.html',
    styleUrls: ['./portfolio.component.scss'],
})
export class PortfolioComponent implements OnInit {
    MOBILE_WIDTH_BREAKPOINT = 768;
    faIcons: Observable<any[]>;
    isSideNavCollapsed = false;

    showOnlyFavorites: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private db: AngularFirestore) {}

    ngOnInit() {
        if (window.innerWidth < this.MOBILE_WIDTH_BREAKPOINT) {
            this.isSideNavCollapsed = true;
        }
    }

    goToSection(section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    login() {}
}
