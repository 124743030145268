import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AuthGuard } from './auth.guard';
import { FirstPageComponent } from './first-page/first-page.component';
import { BombGameComponent } from './games/bomb-game/bombgame.component';
import { WordInLyricsComponent } from './games/word-in-lyrics/word-in-lyrics.component';
import { LoginComponent } from './login/login.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { BlogComponent } from './blog/blog.component';
import { AddBlogPostComponent } from './add-blog-post/add-blog-post.component';
import { CarmelComponent } from './carmel/carmel.component';

const routes: Routes = [
    {
        path: 'main',
        component: FirstPageComponent,
    },
    {
        path: 'portfolio',
        component: PortfolioComponent,
    },
    {
        path: 'blog',
        component: BlogComponent,
    },
    {
        path: 'add-blog-post/:id',
        component: AddBlogPostComponent,
    },
    {
        path: 'add-blog-post',
        redirectTo: 'add-blog-post/',
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'games/word-in-lyrics',
        component: WordInLyricsComponent,
    },
    {
        path: 'games/bombgame',
        component: BombGameComponent,
    },
    {
        path: 'getaway',
        component: CarmelComponent,
    },
    { path: '', redirectTo: '/main', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
